import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { handleRedirect } from "../api/apiHelper";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useTranslation } from "react-i18next";

const SuccessPage: React.FC = () => {
  const { t } = useTranslation();
  useEffect(() => {
    // const timer = setTimeout(() => {
    //   handleRedirect();
    // }, 5000);
    // return () => clearTimeout(timer);
    handleRedirect(5000);
  }, []);

  return (
    <div>
      <div className="text-center mt-16">
        <div>
          <CheckCircleOutlineIcon
            className="text-green-700"
            sx={{ fontSize: 38 }}
          />
        </div>
        <div className="text-sm text-BlackPearl mt-1">
          {t("successPageHeader")}
        </div>
        <div className="text-sm text-BlackPearl mt-1">
          {t("successPageMessage")}
        </div>
        <Button
          variant="outlined"
          className="!mt-2"
          onClick={() => handleRedirect()}
          autoFocus
        >
          {t("continue")}
        </Button>
      </div>
    </div>
  );
};
export default SuccessPage;
