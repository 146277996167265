import React from "react";
import { Button } from "@mui/material";
import face1 from "../assets/left.jpeg";
import face2 from "../assets/center.jpeg";
import face3 from "../assets/right.jpeg";
import { useTranslation } from "react-i18next";
import { CustomAlert } from "../styles/CustomElements";

interface IdentityHelperElementInterface {
  handleInstruction: () => void;
}

const IdentityHelper: React.FC<IdentityHelperElementInterface> = ({
  handleInstruction,
}) => {
  const { t } = useTranslation();

  const images = [
    {
      src: face1,
      alt: t("imageIncorrectAlt1"),
      incorrect: true,
      tips: [t("identityHelperImage1Tip1"), t("identityHelperImage1Tip2")],
    },
    {
      src: face2,
      alt: t("imageCorrectAlt2"),
      correct: true,
      tips: [
        t("identityHelperImage2Tip1"),
        t("identityHelperImage2Tip2"),
        t("identityHelperImage2Tip3"),
        t("identityHelperImage2Tip4"),
      ],
    },
    {
      src: face3,
      alt: t("imageIncorrectAlt3"),
      incorrect: true,
      tips: [t("identityHelperImage3Tip1"), t("identityHelperImage3Tip2")],
    },
  ];

  return (
    <div className="flex flex-col items-center">
      <h1 className="text-xl md:text-2xl font-semibold text-center mb-2 md:mb-4">
        {t("identityHelperTitle")}
      </h1>

      <div className="w-full flex mb-2 md:mb-2">
        <CustomAlert
          severity="warning"
          headerMessage={t("livenessHealthHeader")}
        >
          {t("livenessHealthBody")}
        </CustomAlert>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 w-full mb-2">
        {images.map((image, index) => (
          <div
            key={index}
            className="relative bg-white shadow-md rounded-lg overflow-hidden"
            // style={{ height: "50vh" }}
          >
            <img
              src={image.src}
              alt={image.alt}
              // className="w-full h-44 object-cover"
              className="w-full h-auto object-cover"
            />
            <div className="absolute top-2 right-2"></div>
            <div className="p-4">
              <ul className="space-y-2">
                {image.tips.map((tip, tipIndex) => (
                  <li key={tipIndex} className="flex items-start space-x-2">
                    <span className="text-xs text-gray-400 mt-1">●</span>
                    <span className="md:text-base text-sm">{tip}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>

      {/* Continue Button Section */}
      <div className="w-full flex justify-center">
        <Button
          variant="contained"
          color="primary"
          sx={{ width: "30%", m: 3 }}
          onClick={handleInstruction}
          autoFocus
        >
          {t("continue")}
        </Button>
      </div>
    </div>
  );
};

export default IdentityHelper;
