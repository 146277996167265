import React, { useEffect } from "react";
import { Button, Grid, Typography, Container } from "@mui/material";
import { handleRedirect } from "../../api/apiHelper";
import { useTranslation } from "react-i18next";

const SessionExpired: React.FC = () => {
  const { t } = useTranslation();
  useEffect(() => {
    // Redirect to eVisa portal after 5 seconds
    // const timer = setTimeout(() => {
    //   handleRedirect();
    // }, 5000);

    // // Clean up the timer on component unmount
    // return () => clearTimeout(timer);
    handleRedirect(5000);
  }, []);

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      textAlign="center"
      p={3}
    >
      <Grid item xs={12} md={6}>
        <Typography variant="h4" sx={{ fontWeight: 500, color: "#170F49" }}>
          {t("errorExpiredHeader")}
        </Typography>
        <Typography variant="subtitle1" sx={{ marginTop: 5, color: "#626567" }}>
          {t("errorExpiredMessage1")}
        </Typography>
        <Typography variant="subtitle1" sx={{ marginTop: 2, color: "#626567" }}>
          {t("errorExpiredMessage2")}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleRedirect()}
          sx={{ borderRadius: "20px", padding: "10px 50px", marginTop: 3 }}
        >
          {t("gotoevisaportal")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SessionExpired;
