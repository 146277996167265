import Box from "@mui/system/Box";

import { Outlet } from "react-router-dom";
import Header from "./Header";
// import { useNavigationProtection } from "../Utility/useNavigationProtection";

const PageLayout = () => {
  // useNavigationProtection({
  //   shouldPreventBack: true,
  //   shouldPreventUnload: true,
  //   backConfirmMessage: "Are you sure you want to navigate back?",
  //   unloadConfirmMessage:
  //     "You have unsaved changes. Are you sure you want to leave?",
  // });
  return (
    <>
      <Header />
      <Box sx={{ flex: "1 1 auto" }}>
        <div>
          <Outlet />
        </div>
      </Box>
    </>
  );
};

export default PageLayout;
