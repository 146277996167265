import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Preventing unauthorized Access
export const Validate = () => {
    const navigate = useNavigate();
    const code = sessionStorage.getItem('x-VFS-session');
    console.log(code);
    useEffect(() => {
        if (code)
            return;
        else
            navigate('/404');
        return;
    })
}