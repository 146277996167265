import {
  Menu,
  MenuItem,
  CircularProgress,
  Typography,
  Button,
  Box,
} from "@mui/material";
import css from "./header.module.scss";
import { handleRedirect } from "../../api/apiHelper";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TranslateRoundedIcon from "@mui/icons-material/TranslateRounded";
const languageOptions: Record<string, string> = {
  en: "English (en)",
  pl: "Polski (pl)",
  fr: "Français (fr)",
  hi: "हिन्दी (hi)",
  ru: "русский (ru)",
};

// const usePreventGoingBack = () => {
//   useEffect(() => {
//     // Handle back button
//     const preventGoBack = (e: any) => {
//       e.preventDefault();
//       // Your custom back button logic
//       const shouldLeave = window.confirm("Are you sure you want to go back?");
//       if (shouldLeave) {
//         window.history.back();
//       }
//     };

//     // Handle page leave/refresh/close
//     const handlePageLeave = (e: any) => {
//       e.preventDefault();
//       // Browser will show its own dialog
//       return (e.returnValue = "Are you sure you want to leave?");
//     };

//     // Set up both listeners
//     window.history.pushState(null, "", window.location.pathname);
//     window.addEventListener("popstate", preventGoBack);
//     window.addEventListener("beforeunload", handlePageLeave);

//     // Clean up
//     return () => {
//       window.removeEventListener("popstate", preventGoBack);
//       window.removeEventListener("beforeunload", handlePageLeave);
//     };
//   }, []);
// };

const Header: React.FC = () => {
  // usePreventGoingBack();
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const languageCode = urlParams.get("language")?.toLocaleLowerCase();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedLang, setSelectedLang] = useState<string>("");

  useEffect(() => {
    const languageCodeSession = sessionStorage.getItem("preferedLanguage");
    if (languageCodeSession && languageCodeSession !== languageCode) {
      setSelectedLang(languageCodeSession);
      i18n.changeLanguage(languageCodeSession);
    } else {
      setSelectedLang(languageCode || "en");
      i18n.changeLanguage(languageCode || "en");
      sessionStorage.setItem("preferedLanguage", languageCode || "en");
    }
  }, []);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (lang: string) => {
    setSelectedLang(lang);
    changeLanguage(lang);
    sessionStorage.setItem("preferedLanguage", lang);
    handleClose();
  };
  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };
  const handleCancel = async () => {
    try {
      setLoading(true);
      await handleRedirect(2000);
    } catch (error) {
      console.error("Error in CallBack:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex h-20 items-center px-5 lg:px-24 justify-between">
      <div>
        <img
          src="images/Header/vfs-global-logo.svg"
          className={`${css.logo}`}
          alt={t("altLogo")}
        />
      </div>
      <div className="flex flex-row space-x-5 items-center">
        <div>
          <Typography
            variant="body1"
            // onClick={handleMenuClick}
            style={{ marginTop: 3 }}
          >
            {/* <TranslateRoundedIcon />:{" "}
            {languageOptions[selectedLang] || "English (en)"} */}
            <TranslateRoundedIcon />
            <Box sx={{ display: { xs: "none", md: "inline" } }}>
              : {languageOptions[selectedLang] || "English (en)"}
            </Box>
            <Box sx={{ display: { xs: "inline", md: "none" } }}>
              : {selectedLang || "en"}
            </Box>
          </Typography>
          {/* <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {Object.entries(languageOptions).map(([id, name]) => (
              <MenuItem
                key={id}
                id={id}
                onClick={() => handleLanguageChange(id)}
              >
                {name}
              </MenuItem>
            ))}
          </Menu> */}
        </div>
        {loading ? (
          <CircularProgress />
        ) : (
          <Button variant="outlined" className="!mt-2" onClick={handleCancel}>
            {t("cancel")}
          </Button>
        )}
      </div>
    </div>
  );
};
export default Header;
