import axios from "axios";
import { getUserDimensions, handleRedirect } from "./apiHelper";

const apiUrl = process.env.REACT_APP_BFF_API_SERVER_URL;

const base64ToBlob = (base64: string, mimeType: string): Blob => {
    try {
        // Remove data URL scheme if present
        const base64String = base64.replace(/^data:[a-zA-Z]+\/[a-zA-Z]+;base64,/, '');

        // Decode base64 string
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: mimeType });
    } catch (error) {
        console.error("Error converting base64 to Blob:", error);
        throw error;
    }
};

const dataUrlToBlob = (dataUrl: string, mimeType: string): Blob => {
    // Extract the base64 part of the data URL
    const base64String = dataUrl.replace(/^data:[a-zA-Z]+\/[a-zA-Z]+;base64,/, '');

    // Convert base64 to Blob using the same function as before
    return base64ToBlob(base64String, mimeType);
};




// Define the function with TypeScript types
export const compareFaces = async (
    base64Image: string,
    capturedImage: string,
    base64MimeType: string = 'image/jpeg',
    capturedMimeType: string = 'image/jpeg'
): Promise<any> => {
    try {
        // Convert Base64 to Blob
        const base64Blob = base64ToBlob(base64Image, base64MimeType);

        // Convert captured image (from Webcam) to Blob
        const capturedBlob = dataUrlToBlob(capturedImage, capturedMimeType);

        // Create FormData
        const formData = new FormData();
        formData.append("source_image", base64Blob, "source_file.jpg");
        formData.append("target_image", capturedBlob, "captured_file.jpg");

        const userInfo = getUserDimensions();
        const xVfsSession = sessionStorage.getItem('x-VFS-session');
        const headers = {
            'accept': 'application/json',
            "Content-Type": "multipart/form-data",
            'x-vfs-session': xVfsSession,
            'viewport': `${userInfo.viewport.width}x${userInfo.viewport.height}`,
            'screen_resolution': `${userInfo.screenResolution.width}x${userInfo.screenResolution.height}`, // Use the same dimensions for screen resolution
            'narrative_language': userInfo.narrativeLanguage
        };
        const URL = apiUrl + 'initiate-face-validation';

        const response = await axios.post(URL, formData, {
            headers
        });
        return response.data?.Data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 401) {
                return -1;
            }
        }
        console.error("Error comparing faces:", error);
        throw error;
    }
};
