import axios from "axios";
import { getUserDimensions } from "./apiHelper";


// const apiUrl = process.env.REACT_APP_FACELIVENESS_API_URL;
const apiUrl = process.env.REACT_APP_BFF_API_SERVER_URL;


export const createLivenessSession = async () => {
    try {
        const userInfo = getUserDimensions();
        const xVfsSession = sessionStorage.getItem('x-VFS-session');
        const headers = {
            'accept': 'application/json',
            'x-vfs-session': xVfsSession,
            'viewport': `${userInfo.viewport.width}x${userInfo.viewport.height}`,
            'screen_resolution': `${userInfo.screenResolution.width}x${userInfo.screenResolution.height}`, // Use the same dimensions for screen resolution
            'narrative_language': userInfo.narrativeLanguage
        };
        const URL = `${apiUrl}initiate-liveness-check`;
        // const URL = 'https://o7zg7jddnlkqiwsj2frrzkdlfe0zldwz.lambda-url.ap-south-1.on.aws/create-session';
        console.log(URL);
        const response = await axios.post(URL, {}, { headers });
        // const response = await axios.post(URL);
        return response.data;
        // const data = { "Data": { SessionId: response.data.SessionId } }
        // console.log(data);
        // return data
    } catch (error) {
        console.error('Error fetching Liveness Session', error);
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 401) {
                return -1;
            }
        }
        console.error('Error fetching Liveness Session', error);
        throw error;
    }
};

export const getLivenessSessionResult = async (sessionId: string) => {
    try {
        const userInfo = getUserDimensions();
        const xVfsSession = sessionStorage.getItem('x-VFS-session');
        const headers = {
            'accept': 'application/json',
            'liveness-session-id': sessionId,
            'x-vfs-session': xVfsSession,
            'viewport': `${userInfo.viewport.width}x${userInfo.viewport.height}`,
            'screen_resolution': `${userInfo.screenResolution.width}x${userInfo.screenResolution.height}`, // Use the same dimensions for screen resolution
            'narrative_language': userInfo.narrativeLanguage
        };
        const URL = `${apiUrl}get-liveness-status`;
        const response = await axios.get(URL, { headers });
        // const URL = `https://o7zg7jddnlkqiwsj2frrzkdlfe0zldwz.lambda-url.ap-south-1.on.aws/get-session?session_id=${sessionId}`;
        // const response = await axios.get(URL);
        // console.log('data', response.data);
        return response.data;

    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 401) {
                return -1;
            }
        }
        console.error('Error fetching Liveness Session Result.', error);
        throw error;
    }
};
