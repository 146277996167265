import axios from "axios";
import { getUserDimensions } from "./apiHelper";

const apiUrl = process.env.REACT_APP_BFF_API_SERVER_URL;

export interface PassportResultInterface {
    docfirstname: string;
    doclastname: string;
    docDOB: string;
    birthplace: string;
    docgender: string;
    docnationality: string;
    docnumber: string;
    docexpiry: string;
    docdateofissue: string;
    passport_valid: string;
    validation_reason: string;
    error_code: string;
    error_subcode: string[];
}

interface PassportDetectionFormInterface {
    image: string
}

interface PassportDetectionResultInterface {
    "terminate": boolean, "passport": boolean,
}

const base64ToBlob = (base64: string, mimeType: string): Blob => {
    try {
        // Remove data URL scheme if present
        const base64String = base64.replace(/^data:[a-zA-Z]+\/[a-zA-Z]+;base64,/, '');

        // Decode base64 string
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: mimeType });
    } catch (error) {
        console.error("Error converting base64 to Blob:", error);
        throw error;
    }
};

const dataUrlToBlob = (dataUrl: string, mimeType: string): Blob => {
    // Extract the base64 part of the data URL
    const base64String = dataUrl.replace(/^data:[a-zA-Z]+\/[a-zA-Z]+;base64,/, '');

    // Convert base64 to Blob using the same function as before
    return base64ToBlob(base64String, mimeType);
};



export const passportValidation = async (image: string): Promise<PassportResultInterface | -1> => {
    try {
        const base64Blob = dataUrlToBlob(image, 'image/jpeg');
        const formData = new FormData();
        formData.append("file", base64Blob, "passport.jpg");
        const userInfo = getUserDimensions();
        const xVfsSession = sessionStorage.getItem('x-VFS-session');
        const headers = {
            'accept': 'application/json',
            "Content-Type": "multipart/form-data",
            'x-vfs-session': xVfsSession,
            'viewport': `${userInfo.viewport.width}x${userInfo.viewport.height}`,
            'screen_resolution': `${userInfo.screenResolution.width}x${userInfo.screenResolution.height}`, // Use the same dimensions for screen resolution
            'narrative_language': userInfo.narrativeLanguage
        };
        const URL = apiUrl + 'initiate-passport-validation';

        const response = await axios.post(URL, formData, {
            headers
        });
        return response.data?.Data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 401) {
                return -1;
            }
        }
        console.error("Error Passport Validation:", error);
        throw error;
    }
}

export const passportDetection = async (imageData: PassportDetectionFormInterface): Promise<PassportDetectionResultInterface | -1> => {
    try {
        const userInfo = getUserDimensions();
        const xVfsSession = sessionStorage.getItem('x-VFS-session');
        const headers = {
            'accept': 'application/json',
            'x-vfs-session': xVfsSession,
            'viewport': `${userInfo.viewport.width}x${userInfo.viewport.height}`,
            'screen_resolution': `${userInfo.screenResolution.width}x${userInfo.screenResolution.height}`, // Use the same dimensions for screen resolution
            'narrative_language': userInfo.narrativeLanguage
        };
        const URL = apiUrl + "detect-passport";
        const response = await axios.post(
            URL,
            imageData, { headers }
        );

        return response.data
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 401) {
                return -1;
            }
        }
        console.error("Error Passport Validation:", error);
        throw error;
    }
}