/**
 * @function Theme
 * @params {jsx} children
 * @returns theme of an applicaion.
 */

import { createTheme, ThemeProvider } from "@mui/material/styles";

interface Props {
  children: JSX.Element | JSX.Element[];
}

const Theme = (props: Props) => {
  const theme = createTheme({
    typography: {
      fontFamily: ["Inter-UI-500", "Arial", "sans-serif"].join(","),
    },
    palette: {
      primary: {
        main: "#e86020",
        contrastText: "white",
      },
      secondary: {
        main: "#252525",
        contrastText: "white",
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </>
  );
};

export default Theme;
