import React, { useRef, useState, useEffect } from "react";
import Webcam from "react-webcam";
import Button from "@mui/material/Button";
import {
  PassportResultInterface,
  passportValidation,
} from "../api/apiPassport";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PassportVerification from "./PassportVerification";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import styles from "../styles/common.module.scss";
import step2b from "../assets/step2b.png";
import {
  getGlobalState,
  setGlobalPassportImage,
} from "../Utility/globalVariables";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { CustomAlert } from "../styles/CustomElements";
import { useTranslation } from "react-i18next";
import LinearDeterminate from "../styles/ProgressBar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { compareFaces } from "../api/apiFaceCompare";
interface PassportCaptureElementInterface {
  handleNext: () => void;
}

const countDownConst = 10;

const PassportCapture: React.FC<PassportCaptureElementInterface> = ({
  handleNext,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const webcamRef = useRef<Webcam>(null);
  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  const [isCaptured, setIsCaptured] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isOn, setIsOn] = useState<boolean>(true);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
  const [countdown, setCountDown] = useState<number>(countDownConst);
  const [procced, setProcced] = useState(false);
  const [results, setResults] = useState<PassportResultInterface | null>(null);
  const [isVerified, setIsVerified] = useState(false);
  const successMessageTime = parseInt(
    process.env.REACT_APP_SUCCESS_MESSAGE_TIME || "10000"
  );

  const instructionSteps = {
    step1: t("passport2InstructionStep1"),
    step2: t("passport2InstructionStep2"),
    step3: t("passport2InstructionStep3"),
    step4: t("passport2InstructionStep4"),
    step5: t("passport2InstructionStep5"),
    step6: t("passport2InstructionStep6"),
    step7: t("passport2InstructionStep7", { countDownConst: countDownConst }),
  };
  // Table Content Mapping Kind of table Header
  interface FieldInterface {
    id: keyof PassportResultInterface;
    label: string;
  }

  const fields: FieldInterface[] = [
    { id: "docnumber", label: t("passport2TablePassportNo") },
    { id: "docfirstname", label: t("passport2TableFirstName") },
    { id: "doclastname", label: t("passport2TableLastName") },
    { id: "docDOB", label: t("passport2TableDOB") },
    // { id: "birthplace", label: t("passport2TableBirthPlace") },
    // { id: "docgender", label: t("passport2TableGender") },
    { id: "docnationality", label: t("passport2TableNationality") },
    { id: "docdateofissue", label: t("passport2TableIssueDate") },
    { id: "docexpiry", label: t("passport2TableExpiryDate") },
  ];

  const capturePhoto = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        setCapturedImage(imageSrc);
        setIsCaptured(true);
        setIsOn(false); // Stop webcam
        if (timerId) clearTimeout(timerId); // Clear timer if exists
      }
    }
  };

  const startCountDown = () => {
    setCountDown(countDownConst);
    if (timerId) clearTimeout(timerId);

    const id = setInterval(() => {
      setCountDown((prevCount) => {
        if (prevCount === 1) {
          clearInterval(id); // Stop the interval when countdown reaches 0
          capturePhoto(); // Capture the photo
          return 0; // Ensure countdown stops at 0
        }
        return prevCount - 1;
      });
    }, 1000); // Update countdown every second

    setTimerId(id);
  };

  const retryCapture = () => {
    setCapturedImage(null);
    setIsCaptured(false);
    setIsOn(true); // Restart webcam
    setCountDown(countDownConst); // Reset countdown
    setResults(null);
    setIsSubmitted(false);
  };

  useEffect(() => {
    return () => {
      if (timerId) clearInterval(timerId); // Clean up timer on unmount
    };
  }, [timerId]);

  const handleSubmit = async () => {
    try {
      if (!capturedImage) return;
      setLoading(true);
      const data = await passportValidation(capturedImage);
      console.log("passport", data);
      if (data === -1) {
        navigate("/sessionexpired");
        return;
      }

      if (
        data?.passport_valid &&
        data?.passport_valid?.toLowerCase() === "yes"
      ) {
        // sessionStorage.setItem("PassportImage", capturedImage);
        setGlobalPassportImage(capturedImage);
        const globalImages = getGlobalState();
        const faceImage = globalImages?.globalFaceImage || "";
        const compareData = await compareFaces(faceImage, capturedImage);
        if (compareData === -1) {
          navigate("/sessionexpired");
          return;
        }
        if (compareData?.is_match) {
          setIsSuccess(true);
          handleNext();
        } else {
          setIsSuccess(false);
          data.error_code = t("compareErrorMessage");
        }
      } else {
        setIsSuccess(false);
      }

      setResults(data);
      setIsSubmitted(data ? true : false);
    } catch (error) {
      console.error("Error Submitting the passport: ", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle Next Step
  const handleProceed = () => {
    setProcced(true);
    setIsVerified(true);
    setTimeout(() => {
      setIsVerified(false);
    }, successMessageTime);
  };

  const resultTable = () => {
    return (
      <div className="w-full">
        {results && (
          <TableContainer component={Paper} style={{ height: "auto" }}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: "rgb(248,248,248)",
                      color: "#374151",
                      borderTop: "1px solid #e0e5ea",
                      borderLeft: "1px solid #e0e5ea",
                      borderBottom: "1px solid #e0e5ea",
                    }}
                  >
                    {t("passport2TableLabel")}
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "rgb(248,248,248)",
                      borderTop: "1px solid #e0e5ea",
                      borderRight: "1px solid #e0e5ea",
                      borderBottom: "1px solid #e0e5ea",
                      color: "#374151",
                    }}
                  >
                    {t("passport2TableValue")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((field) => (
                  <TableRow key={field.id}>
                    <TableCell
                      sx={{
                        // fontWeight: "bold",
                        whiteSpace: "nowrap",
                        fontSize: "small",
                        borderLeft: "1px solid #e0e5ea",
                        borderBottom: "1px solid #e0e5ea",
                      }}
                    >
                      {field.label}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "rgb(129,129,129)",
                        whiteSpace: "nowrap",
                        fontSize: "small",
                        borderRight: "1px solid #e0e5ea",
                        borderBottom: "1px solid #e0e5ea",
                      }}
                    >
                      {results?.[field.id] || t("passport2TableDefaultValue")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    );
  };
  if (!procced) {
    return <PassportVerification handleProceed={handleProceed} />;
  }
  return (
    <div className="flex flex-col items-center p-4 w-full rounded-md border border-gray">
      <div className="w-full items-center text-center text-md md:text-lg p-2 font-semibold">
        {t("passportHelperStep2Header")}
      </div>
      {isVerified && (
        <div className="w-full p-2">
          <Alert
            severity="success"
            className={styles.successAlert}
            aria-live="polite"
          >
            <AlertTitle>{t("passport2SuccessHeaderPrevious")}</AlertTitle>
            {t("passport2SuccessMessagePrevious")}
          </Alert>
        </div>
      )}

      {results && (
        <div className="flex flex-col items-center w-full rounded-md border border-gray mb-3 p-2">
          {isSuccess ? (
            <CustomAlert
              severity="success"
              headerMessage={t("passport2SuccessHeader")}
            >
              <p>{t("passport2SuccessMessage")}</p>
              <LinearDeterminate />
              <br />
            </CustomAlert>
          ) : (
            <CustomAlert
              severity="error"
              headerMessage={t("passport2ErrorHeader")}
            >
              <div className="w-full ">{t(results?.error_code)}</div>
            </CustomAlert>
          )}
        </div>
      )}

      {/* Webcam and capture section */}
      <div className="flex flex-col items-center md:w-6/12 w-full rounded-md mb-3">
        {!isCaptured && (
          <div className="w-full p-2">
            <Button
              variant="contained"
              color="primary"
              onClick={startCountDown}
              autoFocus
              disabled={countdown < countDownConst && countdown > 0}
              endIcon={<VideocamOutlinedIcon />}
              fullWidth
            >
              {t("capture")}
            </Button>
          </div>
        )}
        {results && !isSuccess && (
          <div className="w-full p-2">
            <Button
              variant="contained"
              color="primary"
              onClick={retryCapture}
              autoFocus
              fullWidth
            >
              {t("resubmit")}
            </Button>
          </div>
        )}
        <div className="relative w-full p-2">
          {isOn && (
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              className="w-full h-auto rounded-md shadow-md"
              forceScreenshotSourceSize
              videoConstraints={{
                facingMode: "environment",
                height: 1080,
                width: 1920,
              }}
              height="360"
              width="640"
            />
          )}

          {/* Timer overlay */}
          {isOn && countdown < countDownConst && countdown > 0 && (
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="text-6xl md:text-9xl font-bold text-black opacity-60">
                {t(countdown?.toString())}
              </div>
            </div>
          )}

          {/* Display captured image */}
          {isCaptured && capturedImage && (
            <div>
              <img
                src={capturedImage}
                alt={t("altPassportImage")}
                className="w-full h-auto rounded-md shadow-md mb-3"
              />
              {resultTable()}
            </div>
          )}
        </div>

        {/* Capture and retry buttons */}
        <div className="flex w-full p-2">
          {isCaptured && (
            <>
              {!isSubmitted && (
                <div className="flex flex-col w-full space-y-2">
                  <div className="flex w-full items-center bg-gray-200 p-2 rounded-lg">
                    <span className="text-purple-800 mr-8 text-xs ">
                      <QuestionMarkIcon />
                    </span>
                    <span className="text-gray-700 text-xs font-semibold">
                      {t("passport2Confirmation1")}
                    </span>
                  </div>

                  <div className="flex w-full items-center bg-gray-200 p-2 rounded-lg">
                    <span className="text-purple-800 mr-8 text-xs ">
                      <QuestionMarkIcon />
                    </span>
                    <span className="text-gray-700 text-xs font-semibold">
                      {t("passport2Confirmation2")}
                    </span>
                  </div>

                  <div className="flex w-full items-center bg-gray-200 p-2 rounded-lg">
                    <span className="text-purple-800 mr-8 text-xs ">
                      <QuestionMarkIcon />
                    </span>
                    <span className="text-gray-700 text-xs font-semibold">
                      {t("passport2Confirmation3")}
                    </span>
                  </div>

                  <div className="flex w-full space-x-2">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={retryCapture}
                      disabled={loading}
                      fullWidth
                    >
                      {t("retry")}
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      autoFocus
                      fullWidth
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={24} /> : t("submit")}
                    </Button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PassportCapture;
